import {
  OrderInformation,
  OrderTravelInformation,
  TrainOrder,
  useApplicationStore,
} from "@/store/application";
import { AviaSearch, ProductHotel } from "@/types";
import { computed } from "vue";

export const useStore = () => {
  const applicationStore = useApplicationStore();

  const userInfo = computed(() => {
    return applicationStore.user;
  });

  const saveOrderInformation = async (order: OrderInformation) => {
    applicationStore.saveOrderInformation(order);
  };

  const saveOrderTravelInformation = async (order: OrderTravelInformation) => {
    applicationStore.saveOrderTravelInformation(order);
  };

  const saveCurrentAvia = async (avia: AviaSearch) => {
    applicationStore.saveCurrentAvia(avia);
  };

  const saveCurrentTrain = async (train: TrainOrder) => {
    applicationStore.saveCurrentTrain(train);
  };

  const saveCurrentHotel = async (hotel: ProductHotel) => {
    applicationStore.saveCurrentHotel(hotel);
  };

  const showToast = computed(() => applicationStore.showToast);

  const toggleToast = (params: {
    type: "warning" | "error" | null;
    message: string | null;
  }) => {
    applicationStore.toggleToast(params);
  };

  const openToast = (params: {
    type: "warning" | "error" | null;
    message: string | null;
  }) => {
    applicationStore.openToast(params);
  };

  const hideToast = () => {
    applicationStore.hideToasts();
  };

  const countBasket = computed(() => applicationStore.user?.countBasket ?? 0);

  const increaseCountBasket = (qty: number) => {
    applicationStore.setCountBasket((countBasket.value ?? 0) + qty);
  };

  const decreaseCountBasket = (qty: number) => {
    applicationStore.setCountBasket((countBasket.value ?? 0) - qty);
  };

  const clearBasket = () => {
    applicationStore.setCountBasket(0);
  };

  const isAuthUser = computed(() => {
    return applicationStore.auth;
  });

  const currentUser = computed(() => {
    return applicationStore.getUser();
  });

  const getOrderInformation = async () => {
    return await applicationStore.getOrderInformation();
  };

  const getOrderTravelInformation = async () => {
    return await applicationStore.getOrderTravelInformation();
  };

  const getCurrentAvia = async () => {
    return await applicationStore.getCurrentAvia();
  };

  const getCurrentTrain = async () => {
    return await applicationStore.getCurrentTrain();
  };

  const getCurrentHotel = async () => {
    return await applicationStore.getCurrentHotel();
  };

  return {
    showToast,
    toggleToast,
    userInfo,
    countBasket,
    increaseCountBasket,
    decreaseCountBasket,
    isAuthUser,
    saveOrderInformation,
    saveOrderTravelInformation,
    saveCurrentAvia,
    saveCurrentTrain,
    saveCurrentHotel,
    getOrderInformation,
    getOrderTravelInformation,
    getCurrentAvia,
    getCurrentTrain,
    getCurrentHotel,
    hideToast,
    openToast,
    currentUser,
    clearBasket,
  };
};
