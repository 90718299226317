<template>
  <Modal :open="open" @close="onHandleClose" customClass="login-modal">
    <div class="pass-container">
      <div class="pass-wrap remind-pass-wrap">
        <h2 class="mb-16px">Вход</h2>

        <AuthTabs v-model:modal-value="currentTab" />

        <form class="w-100" @submit.prevent="onHandleSubmit">
          <ErrorMsg
            v-if="errorMessage"
            :errorMsg="errorMessage"
            @clear="clearErrorMsg"
          />
          <PhoneInput
            v-if="currentTab === 'phone'"
            class="mb-16px"
            :isValidPhone="isValidPhone"
            v-model="phone"
          />

          <NumberMemberInput
            v-if="currentTab === 'code'"
            placeholder="00000000000"
            hint="Индивидуальный номер Клиента Банка Новиком"
            class="mb-16px"
            :isValidMemberNumber="isValidMemberNumber"
            v-model="memberNumber"
          />
          <PasswordInput
            placeholder="Пароль"
            :isValidPassword="true"
            :enabled-validation="false"
            class="mb-8px"
            v-model:modal-value="password"
          />
          <button class="forget-password mb-16px" @click="onRemindPassword">
            Забыли пароль?
          </button>
          <AuthBtn title="Войти" type="submit" :disabled="disabledSignIn" />
          <div class="link-sign-in">
            Нет аккаунта?
            <span @click="onRegistration">Регистрация</span>
          </div>
        </form>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { defineProps, toRefs, defineEmits, computed } from "vue";
import Modal from "@/components/elements/Modal.vue";
import PhoneInput from "@/components/elements/inputs/PhoneInput.vue";
import PasswordInput from "@/components/elements/inputs/PasswordInput.vue";
import NumberMemberInput from "@/components/elements/inputs/NumberMemberInput.vue";
import AuthBtn from "@/components/elements/buttons/AuthBtn.vue";
import AuthTabs from "@/components/elements/AuthTabs.vue";
import { useInput } from "@/composables/useInput";
import ErrorMsg from "@/components/elements/form/ErrorMsg.vue";

const props = defineProps<{
  open: boolean;
}>();
const {
  currentTab,
  phone,
  memberNumber,
  password,
  isValidPhone,
  currentName,
  isValidMemberNumber,
  clearInputs,
  clearErrorMsg,
  errorMessage,
} = useInput();

const { open } = toRefs(props);

const $emit = defineEmits([
  "close",
  "showRegistration",
  "showRemind",
  "sendSignIn",
]);

const onRemindPassword = () => {
  $emit("showRemind");
  clearInputs();
};

const onRegistration = () => {
  $emit("showRegistration");
  clearInputs();
};

const onHandleClose = () => {
  clearInputs();
  $emit("close");
};

const onHandleSubmit = () => {
  signIn();
};

const disabledSignIn = computed(() => {
  return (
    (currentTab.value === "phone" && !phone.value) ||
    (currentTab.value === "code" && !memberNumber.value) ||
    !password.value ||
    (currentTab.value === "code" && !isValidMemberNumber.value) ||
    (currentTab.value === "phone" && !isValidPhone.value)
  );
});

const signIn = async () => {
  $emit(
    "sendSignIn",
    {
      userName: currentName.value,
      password: password.value,
    },
    onHandleClose
  );
};
</script>

<style lang="scss" scoped>
.pass-container {
  max-width: fit-content;
  margin-inline: auto;
}
.pass-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.pass-wrap h2 {
  margin-bottom: 40px;
  font-size: 24px;
  font-family: Gilroy-Medium !important;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0;
  color: var(--text_color);
}
.pass-wrap label {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}
.pass-wrap label:not(:last-child) {
  margin-bottom: 16px;
}
.pass-wrap input {
  height: 40px;
  width: 100%;
  padding: 12px 16px;
  border-radius: var(--bdrs-7);
  border: var(--border-grey);
  font-size: 16px;
  font-family: Gilroy-Regular !important;
  font-weight: 400;
  line-height: 100%;
  color: var(--text_color);
}
.pass-wrap input::placeholder {
  font-size: 16px;
  font-family: Gilroy-Regular !important;
  font-weight: 400;
  line-height: 100%;
  color: var(--grey-79);
  transition: outline 0.2s;
}
.pass-wrap input:focus {
  outline: 1px solid var(--primary_bg);
}
.pass-input-label {
  text-align: start;
  margin-bottom: 4px;
  font-size: 12px;
  font-family: Gilroy-Regular !important;
  font-weight: 400;
  line-height: 100%;
  color: var(--grey-79);
}

.remind-pass-wrap {
  min-width: 376px;
  max-width: 376px;
}

.remind-pass-wrap a {
  display: flex;
  margin-top: -17px;
}

.remind-pass-wrap h2 {
  margin-bottom: 40px;
}

.remind-pass-wrap form {
  max-width: 281px;
}

.remind-pass-wrap input::placeholder {
  color: #c8c8c8;
}

.remind-pass-switch-wrap {
  display: flex;
  gap: 27px;
  margin-bottom: 55px;
}

.remind-pass-switch {
  display: flex;
  align-items: center;
  gap: 9px;
  padding: 0;
  text-wrap: nowrap;
  font-size: 14px;
  font-family: Gilroy-Regular !important;
  font-weight: 400;
  line-height: 100%;
  color: var(--grey-79);
  transition: color 0.2s;
}

.remind-pass-switch_primary,
.remind-pass-switch:hover {
  color: var(--primary_bg);
}

.remind-pass-input-label {
  margin-top: 7px;
  margin-bottom: 0;
  text-align: center;
  line-height: 120%;
}

.pass-input-label_ls {
  letter-spacing: -0.3px;
  margin-bottom: 8px;
}

.remind-pass-switch-wrap_mb {
  margin-bottom: 51px;
}

@media (max-width: 576px) {
  .remind-pass-wrap {
    margin-top: 15px;
    margin-bottom: 46px;
    min-width: auto !important;
    max-width: auto !important;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .new-pass-wrap {
    max-width: 100%;
    padding-inline: 16px;
  }

  .new-pass-inputs {
    gap: 11px;
    max-width: 286px;
    margin-inline: auto;
    justify-content: initial;
  }

  .remind-pass-switch {
    flex-direction: column;
  }

  .remind-pass-wrap h2 {
    font-size: 22px;
    margin-bottom: 30px;
  }

  .remind-pass-switch-wrap {
    gap: 32px;
  }

  .remind-pass-switch-wrap_mb {
    margin-bottom: 42px;
  }

  .remind-pass-switch-wrap_member {
    margin-bottom: 44px;
  }

  .remind-pass-wrap form {
    max-width: 327px;
  }
}

.remind-pass-switch:hover svg path {
  transition: stroke 0.2s;
}

.remind-pass-switch_primary svg path,
.remind-pass-switch:hover svg path {
  stroke: var(--primary_bg);
}

.forget-password {
  color: var(--primary_bg);
  text-align: center;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  width: 100%;
  text-align: left;
  padding: 0;
}

.link-sign-in {
  color: var(--grey-79);
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin-top: 40px;

  span {
    display: inline-block;
    color: var(--text_color);
    position: relative;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -2px; /* Adjust as needed */
      width: 100%;
      height: 1px;
      background-color: var(--text_color); /* Adjust as needed */
    }
  }
}
</style>
<style>
@media (max-width: 576px) {
  .login-modal .modal .remind-pass-wrap {
    margin: 0;
  }
}
</style>
